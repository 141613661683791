<template>
  <div class="video-card">
    <div>
      <p class="video-title mb-1">{{ video.title }}</p>
      <div v-if="showStatus" class="status text-center mb-2">
        {{ video.status }}
      </div>
    </div>

    <video-embed
      css="embed-responsive-16by9"
      :src="video.videoLink"
    ></video-embed>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    video: {
      type: Object
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fp-videos {
  .status {
    background: $color-main;
    color: white;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;
    width: fit-content;
  }
  .video-title {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
